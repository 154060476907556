import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";

// Import local images
import shoppingImg from "../Image/1.png";
import foodImg from "../Image/2.png";
import pubsImg from "../Image/3.png";
import groceryImg1 from "../Image/4.png";
import groceryImg2 from "../Image/5.png";
import groceryImg3 from "../Image/6.png";

// Array of category images
const categories = [
  { image: shoppingImg },
  { image: foodImg },
  { image: pubsImg },
  { image: groceryImg1 },
  { image: groceryImg2 },
  { image: groceryImg3 },
];

const LogoCarousel: React.FC = () => {
  return (
    <div className="flex justify-center my-6">
      <div className="w-full max-w-full">
        <Swiper
          modules={[Autoplay]}
          loop={true}
          spaceBetween={30}
          slidesPerView={4} // Adjust this value to control how many slides are visible
          autoplay={{
            delay: 0, // Continuous scrolling
            disableOnInteraction: false,
          }}
          speed={2000} // Adjust speed for smoother scrolling
          className="w-full"
        >
          {categories.map((category, index) => (
            <SwiperSlide key={index} className="flex items-center justify-center">
              <img src={category.image} alt={`Category ${index}`} className="h-20 w-auto" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default LogoCarousel;
