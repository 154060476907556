import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import style from '../styles/Navbar.module.css';
import logo from '../Image/logo.png';

const Navbar: React.FC = () => {
    const [menuActive, setMenuActive] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const menuRef = useRef<HTMLDivElement | null>(null);
    const lastScrollY = useRef(0);
    const navigate = useNavigate();

    const toggleMenu = () => {
        setMenuActive((prev) => !prev);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
            setMenuActive(false);
        }
    };

    const handleScroll = () => {
        if (window.scrollY > lastScrollY.current) {
            setIsVisible(false); // Scrolling down - hide navbar
        } else {
            setIsVisible(true);  // Scrolling up - show navbar
        }
        lastScrollY.current = window.scrollY;
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        window.addEventListener('scroll', handleScroll);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleLogoClick = () => {
        navigate('/');
        window.location.reload();
    };

    return (
        <nav className={`${style.navbar} ${isVisible ? style.show : style.hide}`}>
            <div className="container mx-auto flex justify-between items-center">
                <div className={`text-xl font-bold ${style.brandLogoText}`}>
                    <div className="mr-3">
                        <img
                            className={`${style.logo}`}
                            src={logo}
                            alt="Example Section"
                            onClick={handleLogoClick}
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                    <div className={`${style.logoText}`}>
                        <div className={`${style.brand}`}>REKER</div>
                        <div className={`${style.tagLine}`}>MISSION RE:EARTH</div>
                    </div>
                </div>
                <div className={`${style.hamburger}`} onClick={toggleMenu}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div className={`${style.menu} ${menuActive ? style.active : ""}`} ref={menuRef}>
                    <NavLink
                        to="/"
                        className={({ isActive }) =>
                            isActive
                                ? `${style.navLink} ${style.navLinkActive}`
                                : `${style.navLink}`
                        }
                        onClick={toggleMenu}
                    >
                        HOME
                    </NavLink>
                    <NavLink
                        to="/about"
                        className={({ isActive }) =>
                            isActive
                                ? `${style.navLink} ${style.navLinkActive}`
                                : `${style.navLink}`
                        }
                        onClick={toggleMenu}
                    >
                        ABOUT
                    </NavLink>
                    <NavLink
                        to="/login"
                        className={({ isActive }) =>
                            isActive
                                ? `${style.navLink} ${style.navLinkActive}`
                                : `${style.navLink}`
                        }
                        onClick={toggleMenu}
                    >
                        EXPLORE
                    </NavLink>
                    <NavLink
                        to="/contact"
                        className={({ isActive }) =>
                            isActive
                                ? `${style.navLink} ${style.navLinkActive}`
                                : `${style.navLink}`
                        }
                        onClick={toggleMenu}
                    >
                        CONTACT
                    </NavLink>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
