import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Track from "../Image/track.png";
import Earn from "../Image/earn.png";
import Earth from "../Image/earth.png";
import coin from "../Image/reker_coin.png";

const ImageSection: React.FC<{ showOverlay: boolean }> = ({ showOverlay }) => {
  const myVideo = require("../video/Second_frame.mp4");

  return (
    <section className="relative w-full h-screen bg-black">
      <video
        className="absolute top-0 left-0 w-full h-full object-cover sm:object-contain z-0"
        src={myVideo}
        autoPlay
        muted
        loop
        playsInline
      />
      <div className="relative z-10 text-center py-32 px-6">
        <h1 className="text-5xl md:text-7xl text-white font-extrabold animate-fadeInUp mb-6">
          Welcome to <span className="text-green-400">Reker</span>
        </h1>
        <p className="text-2xl md:text-4xl font-semibold text-white mb-8">
          India’s first{" "}
          <span className="text-green-400">AR-Gamified</span> rewarding
          platform
        </p>
      </div>
      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-5"></div>
    </section>
  );
};

const features = [
  {
    img: Track,
    title: "Track Your Commute",
    desc: "Automatically track your eco-friendly commutes with ease.",
  },
  {
    img: Earn,
    title: "Earn Rewards",
    desc: "Get credits and rewards for making sustainable choices.",
  },
  {
    img: Earth,
    title: "Support the Planet",
    desc: "Help reduce carbon emissions and make a difference.",
  },
];

const Home: React.FC = () => {
  const [showOverlay, setShowOverlay] = useState(false);

  useEffect(() => {
    const handleScroll = () => setShowOverlay(true);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleWaitlistClick = () => {
    toast.success("Thank you! You have joined. Welcome to Reker!", {
      position: "top-center",
    });
  };

  return (
    <div className="bg-gray-900 text-white">
      <ToastContainer />
      <main>
        {/* Hero Section */}
        <ImageSection showOverlay={showOverlay} />

        {/* Features Section */}
        <section className="py-20 bg-gray-900">
          <div className="max-w-7xl mx-auto px-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12">
              {features.map(({ img, title, desc }, idx) => (
                <div
                  key={idx}
                  className="bg-gray-800 rounded-xl shadow-lg hover:shadow-2xl hover:scale-105 transition-transform duration-300 p-8 flex flex-col items-center text-center"
                >
                  <div className="w-24 h-24 mb-6 rounded-full bg-white flex items-center justify-center">
                    <img
                      src={img}
                      alt={title}
                      className="w-16 h-16 object-contain"
                    />
                  </div>
                  <h3 className="text-2xl font-bold mb-4 text-white">
                    {title}
                  </h3>
                  <p className="text-gray-300">{desc}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Eco-Friendly Modes Section */}
        <section className="py-20 bg-gradient-to-b from-gray-900 to-gray-800">
          <div className="max-w-5xl mx-auto px-6 text-center">
            <h2 className="text-3xl md:text-4xl font-extrabold text-green-400 mb-8">
              Earn Personal Carbon Credits & Coins
            </h2>
            <p className="text-lg md:text-xl text-gray-300 mb-12">
              By using eco-friendly modes of commute like metro, walking, EV
              rides, and buses to save the environment while on the go.
            </p>
            <img
              src={coin}
              alt="Earn Carbon Credits"
              className="mx-auto rounded-lg shadow-md w-80"
            />
          </div>
        </section>

        {/* Video Section */}
        <section className="relative w-full h-screen flex items-center justify-center bg-black">
  <video
    className="absolute top-0 left-0 w-full h-full object-cover sm:object-contain z-0"
    src={require("../video/my_video1.mp4")}
    autoPlay
    muted
    loop
    playsInline
  />
  <div className="relative z-10 p-10 text-center">
    <p className="text-5xl md:text-4xl font-semibold text-white mb-8">
      World’s first Climate Combative Lifestyle App
    </p>
    <button
      onClick={handleWaitlistClick}
      className="px-12 py-4 text-lg font-semibold bg-gradient-to-r from-green-400 to-blue-500 text-white rounded-full shadow-lg hover:shadow-xl hover:scale-105 transform transition-all duration-300"
    >
      Join the Waitlist
    </button>
  </div>
  <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-60"></div>
</section>
      </main>
    </div>
  );
};

export default Home;
